"use strict";

const moment = require("moment");
const CommonUtils = require("../generic/common_utils");
const CacheMap = require("../../utils/cache_map");
const escape = require("escape-html");
const {CommonCriticalityForLink} = require("../risk/common_criticality_for_link");
const {CommonCriticalityForRecord} = require("../risk/common_criticality_for_record");

module.exports.NOT_ASSESSED_SCORE = 0;
module.exports.NOT_ASSESSED_SCALE = 0;
module.exports.NOT_ASSESSED_LABEL = "Not Assessed";
module.exports.NOT_ASSESSED_DESCRIPTION_LABEL = "Risk is not assessed.";
module.exports.MODELS_WITH_CUSTOM_RISK_ASSESSMENT_METHOD = ["PP", "IQA", "IPA", "MA"];
module.exports.RISK_ASSESSMENT_METHOD = {
  RISK_RANKING: "RiskRanking",
  CLASSIFICATION: "Classification"
};
module.exports.DEFAULT_RISK_ASSESSMENT_METHOD = exports.RISK_ASSESSMENT_METHOD.RISK_RANKING;
module.exports.RISK_SUPPORTING_DOCUMENT_LABELS = ["Risk Label Justification", "Scale Justification", "Recommended Action", "Scale Dependent"];

module.exports.CRITICALITY_RULES = {
  DOWNGRADE_PREVIOUS: "N-1",
  DOWNGRADE_KEY: "KEY",
  ALWAYS_CRITICAL: "AlwaysCritical",
  POTENTIAL: "Potential",
  MAXIMUM: "Maximum"
};

const RISK_TYPE_ENUM = {
  IMPACT: "Impact",
  UNCERTAINTY: "Uncertainty",
  CRITICALITY: "Criticality",
  CAPABILITY_RISK: "Capability Risk",
  PROCESS_RISK: "Process Risk",
  DETECTABILITY_RISK: "Detectability Risk",
  RPN: "RPN",
};

module.exports.RISK_TYPE_ENUM = RISK_TYPE_ENUM;

module.exports.PROCESS_CAPABILITY_TYPES = {
  Poor: {
    label: "Poor",
    color: "#da0a36",
    colorLabel: "Red",
    maxValue: 0.667,
    includingMaxValue: true,
  },
  Marginal: {
    label: "Marginal",
    color: "#f79646",
    colorLabel: "Orange",
    maxValue: 1,
  },
  Capable: {
    label: "Capable",
    color: "#f1c94d",
    colorLabel: "Yellow",
    maxValue: 1.333,
  },
  Good: {
    label: "Good",
    color: "#629216",
    colorLabel: "Green",
    default: true,
    maxValue: Number.MAX_SAFE_INTEGER,
  },
};

module.exports.RMP_TO_RISK_ATTRIBUTES = {
  RMPToImpacts: {
    name: "RMPImpacts",
    linkedObject: "RMPToImpact",
    linkedObjectVersion: "RMPToImpactLinkedVersions",
    isRiskScore: true,
  },
  RMPToUncertainties: {
    name: "RMPUncertainties",
    linkedObject: "RMPToUncertainty",
    linkedObjectVersion: "RMPToUncertaintyLinkedVersions",
    isRiskScore: true,
  },
  RMPToCriticalities: {
    name: "RMPCriticalities",
    linkedObject: "RMPToCriticalityScale",
    linkedObjectVersion: "RMPToCriticalityScaleLinkedVersions",
    isRiskScore: false,
  },
  RMPToCapabilityRisks: {
    name: "RMPCapabilityRisks",
    linkedObject: "RMPToCapabilityRisk",
    linkedObjectVersion: "RMPToCapabilityRiskLinkedVersions",
    isRiskScore: true,
  },
  RMPToProcessRiskScale: {
    name: "RMPProcessRisks",
    linkedObject: "RMPToProcessRiskScale",
    linkedObjectVersion: "RMPToProcessRiskScaleLinkedVersions",
    isRiskScore: false,
  },
  RMPToDetectabilityRisks: {
    name: "RMPDetectabilityRisks",
    linkedObject: "RMPToDetectabilityRisk",
    linkedObjectVersion: "RMPToDetectabilityRiskLinkedVersions",
    isRiskScore: true,
  },
  RMPToRPNScales: {
    name: "RMPToRPN",
    linkedObject: "RMPToRPNScale",
    linkedObjectVersion: "RMPToRPNScaleLinkedVersions",
    isRiskScore: false,
  },
};

// Need this for translation
module.exports.RISK_LABELS = {
  NON_CRITICAL: "Non-Critical",
  KEY: "Key",
  CRITICAL: "Critical",
};

/* The RISK_MODELS define all the attribute models in the software which can accept some sort of risk information. Those
   would be any models that have at least one of impact, uncertainty, capabilityRisk and detectabilityRisk defines. For each
   risk model, the isRiskLink attribute is set to true if the respective record is a risk link in the sequelize UML diagram
   or a just an editable.
 */
module.exports.TECH_TRANSFER_RISK_MODELS = ["PP", "PRC", "MA", "MT", "IQA", "IPA"];
module.exports.RISK_MODELS = [
  {
    model: "FQA",
    isRiskLink: false,
  },
  {
    model: "FPA",
    isRiskLink: false,
  },
  {
    model: "IQA",
    isRiskLink: false,
  },
  {
    model: "IPA",
    isRiskLink: false,
  },
  {
    model: "PA",
    isRiskLink: false,
  },
  {
    model: "MA",
    isRiskLink: false,
  },
  {
    model: "PP",
    isRiskLink: false,
  },
  {
    model: "IQATOFQA",
    isRiskLink: true,
    from: "IQA",
    to: "FQA",
  },
  {
    model: "TT",
    isRiskLink: false,
  },
  {
    model: "IQATOIQA",
    isRiskLink: true,
    from: "IQA",
    to: "IQA",
  },
  {
    model: "IQATOFPA",
    isRiskLink: true,
    from: "IQA",
    to: "FPA",
  },
  {
    model: "IQATOIPA",
    isRiskLink: true,
    from: "IQA",
    to: "IPA",
  },
  {
    model: "IPATOFPA",
    isRiskLink: true,
    from: "IPA",
    to: "FPA",
  },
  {
    model: "IPATOIQA",
    isRiskLink: true,
    from: "IPA",
    to: "IQA",
  },
  {
    model: "IPATOFQA",
    isRiskLink: true,
    from: "IPA",
    to: "FQA",
  },
  {
    model: "IPATOIPA",
    isRiskLink: true,
    from: "IPA",
    to: "IPA",
  },
  {
    model: "PPTOFQA",
    modelAlias: "ProcessParameterToFQA",
    isRiskLink: true,
    from: "ProcessParameter",
    to: "FQA",
  },
  {
    model: "PPTOIQA",
    modelAlias: "ProcessParameterToIQA",
    isRiskLink: true,
    from: "ProcessParameter",
    to: "IQA",
  },
  {
    model: "PPTOFPA",
    modelAlias: "ProcessParameterToFPA",
    isRiskLink: true,
    from: "ProcessParameter",
    to: "FPA",
  },
  {
    model: "PPTOIPA",
    modelAlias: "ProcessParameterToIPA",
    isRiskLink: true,
    from: "ProcessParameter",
    to: "IPA",
  },
  {
    model: "MATOFQA",
    modelAlias: "MaterialAttributeToFQA",
    isRiskLink: true,
    from: "MaterialAttribute",
    to: "FQA",
  },
  {
    model: "MATOIQA",
    modelAlias: "MaterialAttributeToIQA",
    isRiskLink: true,
    from: "MaterialAttribute",
    to: "IQA",
  },
  {
    model: "MATOFPA",
    modelAlias: "MaterialAttributeToFPA",
    isRiskLink: true,
    from: "MaterialAttribute",
    to: "FPA",
  },
  {
    model: "MATOIPA",
    modelAlias: "MaterialAttributeToIPA",
    isRiskLink: true,
    from: "MaterialAttribute",
    to: "IPA",
  },
  {
    model: "FQAToGeneralAttributeRisk",
    isRiskLink: true,
    from: "FQA",
    to: "GA",
  },
  {
    model: "FPAToGeneralAttributeRisk",
    isRiskLink: true,
    from: "FPA",
    to: "GA",
  },
];

const RISK_COLORS = {
  NONE: "None",
  BLUE: "Blue",
  GREEN: "Green",
  YELLOW: "Yellow",
  ORANGE: "Orange",
  RED: "Red",
  GREY: "Grey"
};

module.exports.RISK_COLORS = RISK_COLORS;

/**
 * RMP doesnt deal with IPA/FPA separately. They both are considered as performance attributes.
 * @param modelName The model name to get the effective RMP for
 * @returns {string|*}
 */
function getCorrectedModelNameForRMP(modelName) {
  switch (modelName) {
    case "FPA":
    case "IPA":
      return "PA";
    default:
      return modelName;
  }
}

/**
 * Given an array of objects, it will set the risk default values for impact, uncertainty, capabilityRisk and
 * detectabilityRisk if these are not already set.
 * @param model The instance model to set default values
 * @param effectiveRMP  The RMP based on which the default risk values will be set
 * @param object The object to set default values (created from the Excel import file).
 * @param objectFromDB {{}?} The instance from the database.
 */
module.exports.setRiskDefaultValues = function(model, effectiveRMP, object, objectFromDB) {
  const modelAttributes = Object.keys(model.getAttributes());
  if (effectiveRMP && effectiveRMP.boundaries) {
    const isRiskRanking = object.riskAssessmentMethod && exports.isRecordRiskRanking(object);
    if (modelAttributes.includes("impact") && !object.impact && !isRiskRanking) {
      object.impact = objectFromDB ? objectFromDB.impact : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.IMPACT, effectiveRMP) ? effectiveRMP.boundaries.minImpact : effectiveRMP.boundaries.maxImpact);
    }
    if (modelAttributes.includes("uncertainty") && !object.uncertainty) {
      object.uncertainty = objectFromDB ? objectFromDB.uncertainty : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP) ? effectiveRMP.boundaries.minUncertainty : effectiveRMP.boundaries.maxUncertainty);
    }
    if (modelAttributes.includes("capabilityRisk") && !object.capabilityRisk) {
      object.capabilityRisk = objectFromDB ? objectFromDB.capabilityRisk : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP) ? effectiveRMP.boundaries.minCapabilityRisk : effectiveRMP.boundaries.maxCapabilityRisk);
    }
    if (modelAttributes.includes("detectabilityRisk") && !object.detectabilityRisk) {
      object.detectabilityRisk = objectFromDB ? objectFromDB.detectabilityRisk : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP) ? effectiveRMP.boundaries.minDetectabilityRisk : effectiveRMP.boundaries.maxDetectabilityRisk);
    }
  }
};

module.exports.setTechTransferRiskDefaultValues = function(model, effectiveTechTransferRMP, object, objectFromDB) {
  const modelAttributes = Object.keys(model.getAttributes());
  if (effectiveTechTransferRMP && effectiveTechTransferRMP.boundaries) {
    if (modelAttributes.includes("techTransferImpact") && !object.techTransferImpact) {
      object.techTransferImpact = objectFromDB ? objectFromDB.techTransferImpact : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.IMPACT, effectiveTechTransferRMP) ? effectiveTechTransferRMP.boundaries.minImpact : effectiveTechTransferRMP.boundaries.maxImpact);
    }
    if (modelAttributes.includes("techTransferUncertainty") && !object.techTransferUncertainty) {
      object.techTransferUncertainty = objectFromDB ? objectFromDB.techTransferUncertainty : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.UNCERTAINTY, effectiveTechTransferRMP) ? effectiveTechTransferRMP.boundaries.minUncertainty : effectiveTechTransferRMP.boundaries.maxUncertainty);
    }
    if (modelAttributes.includes("techTransferDetectability") && !object.techTransferDetectability) {
      object.techTransferDetectability = objectFromDB ? objectFromDB.techTransferDetectability : (exports.hasNotAssessedRiskScale(exports.RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveTechTransferRMP) ? effectiveTechTransferRMP.boundaries.minDetectabilityRisk : effectiveTechTransferRMP.boundaries.maxDetectabilityRisk);
    }
  }
};

module.exports.isInstanceVersion = function(instance) {
  let isInstanceVersion;
  if (instance.dataValues) {
    isInstanceVersion = !CommonUtils.isPropertyDefined(instance.dataValues, "LastApprovedVersionId");
  } else {
    isInstanceVersion = !CommonUtils.isPropertyDefined(instance, "LastApprovedVersionId");
  }

  return isInstanceVersion;
};

/**
 * Gets the effective RMP for the instance, base on the updatedAt date on the instance.
 * The effective RMP for the instance is the approved version of the RMP at the time the instance was updated/saved to the DB
 * For new instances where the updatedAt date is not yet set, it returns the most current approved RMP
 * @param projectOrProjectVersions Project including all historical versions
 * @param rmpVersions A list of all approved RMP versions
 * @param instanceOrInstanceVersion The instance or instance version for which the effective RMP will be returned
 * @param modelName Model to get RMP for in case RMP is configured by type
 * @param useCurrentDate
 */
module.exports.getEffectiveRMPByModelName = function(projectOrProjectVersions, rmpVersions, instanceOrInstanceVersion, modelName, useCurrentDate = false, isInstanceVersion = null) {
  modelName = getCorrectedModelNameForRMP(modelName);

  let date = null;
  if (instanceOrInstanceVersion.createdAt && !useCurrentDate) {
    if (isInstanceVersion === null) {
      isInstanceVersion = exports.isInstanceVersion(instanceOrInstanceVersion);
    }

    /* If a version of an instance is passed in, then use the createdAt field of the version to get the effective RMP for it,
       otherwise use the updatedAt field of the instance, which is the latest date the instance was updated. */

    const dateFromInstance = isInstanceVersion ? instanceOrInstanceVersion.createdAt : instanceOrInstanceVersion.updatedAt;
    date = moment(dateFromInstance).utc();
  }

  if (useCurrentDate) {
    date = moment.utc();
  }

  return exports.getEffectiveRMPForDate(projectOrProjectVersions, rmpVersions, date, modelName);
};

/**
 * Gets the effective RMP for a given date.
 * The effective RMP for given date is the approved version of the RMP at that date
 * If a date is not provided then the most current approved RMP is returned
 * @param projectOrProjectVersions Project including all historical versions
 * @param rmpVersions A list of all approved RMP versions
 * @param date The date for which the approved RMP version is returned
 * @param modelName Model to get RMP for in case RMP is configured by type
 */
module.exports.getEffectiveRMPForDate = function(projectOrProjectVersions, rmpVersions, date, modelName) {
  let projectVersions = [];
  if (projectOrProjectVersions) {
    if (projectOrProjectVersions.allVersionsWithDetails) {
      projectVersions = projectOrProjectVersions.allVersionsWithDetails.length === 0 ? [projectOrProjectVersions] : projectOrProjectVersions.allVersionsWithDetails;
    }

    if (Array.isArray(projectOrProjectVersions)) {
      projectVersions = projectOrProjectVersions;
    }
  }

  modelName = getCorrectedModelNameForRMP(modelName);

  const filterPastVersions = (version) => {
    let versionCreatedAtDate = moment(version.createdAt).utc();
    if (date) {
      return versionCreatedAtDate.isSameOrBefore(moment(date).utc());
    } else {
      return version;
    }
  };

  let pastRMPs = rmpVersions.filter(filterPastVersions);

  let effectiveProjectVersion = null;
  let effectiveRMP = null;
  // project versions can be null for version created before FlexibleRMP feature was released
  let pastProjects = projectVersions.filter(filterPastVersions).filter(version => version.rmpVersionId > 0);
  if (pastProjects.length > 0) {
    effectiveProjectVersion = pastProjects.sort(CommonUtils.sortBy({name: "id", reverse: true}))[0];
  }

  if (effectiveProjectVersion && effectiveProjectVersion.rmpVersionId) {
    effectiveRMP = rmpVersions.find(rmpVersion => rmpVersion.id === effectiveProjectVersion.rmpVersionId);
  }

  if (effectiveRMP) {
    return exports.filterRMPByType(effectiveRMP, modelName);
  }

  if (pastRMPs.length > 0) {
    effectiveRMP = pastRMPs.sort((a, b) => {
      return b.id - a.id;
    })[0];
  } else { //This is possible only during a migration where the instances created date is before the first approved RMP date. In this case return the first RMP approved version
    effectiveRMP = rmpVersions.sort((a, b) => {
      return a.id - b.id;
    })[0];
  }

  return exports.filterRMPByType(effectiveRMP, modelName);
};

// On the server, uses a CacheMap, so we don't keep RMP instances on cache forever. On the client, we just use a normal map.
const originalRMPs = CommonUtils.IS_BACKEND ? new CacheMap(64, 600) : new Map();

/**
 * Retrieves the original RMP for the given filtered RMP from the cache
 * (or just returns the given RMP in case it is already the original, unfiltered one).
 * @param filteredRMP
 * @returns {any}
 */
function getOriginalRMP(filteredRMP) {
  let result = filteredRMP;

  if (filteredRMP.originalRMPID) {
    let originalRMP = originalRMPs.get(filteredRMP.originalRMPID);

    if (originalRMP) {
      result = originalRMP;
    }
  }
  return result;
}

module.exports.getOriginalRMP = getOriginalRMP;

/**
 * Links the original RMP to the filtered one, so we can always get back to the original values.
 * @param originalRMP
 * @param filteredRMP
 */
function setOriginalRMP(originalRMP, filteredRMP) {
  // Originally, this was just a variable saved inside the cloned RMP pointing to the original RMP,
  // but that created a circular reference in the JSON. So we just store the ID and retrieve it from the cache
  if (!originalRMP.rmpCacheID) {
    originalRMP.rmpCacheID = CommonUtils.generateUUID();
  }
  filteredRMP.originalRMPID = originalRMP.rmpCacheID;
  originalRMPs.set(originalRMP.rmpCacheID, originalRMP);
}

module.exports.setOriginalRMP = setOriginalRMP;

/**
 * Given an RMP, filters it by type. If it's already filtered, tries to retrieve the original unfiltered one from the
 * cache
 * @param rmp
 * @param typeCodeOrModelName
 * @returns {{configureByType}|*}
 */
function getFilteredRMPForType(rmp, typeCodeOrModelName) {
  let originalRMP = getOriginalRMP(rmp);

  return originalRMP.configureByType && typeCodeOrModelName
    ? filterRMPByType(originalRMP, typeCodeOrModelName)
    : rmp;
}

module.exports.getFilteredRMPForType = getFilteredRMPForType;

/**
 * This function filters out RMP by model type
 * @param rmp to be filtered
 * @param modelNameOrTypeCode to filter the RMP with
 * @param includeConfigureByTypeSettings either to include configure by type settings or not
 * @param filterRiskScoresOnly return RMP with only filtered risk score arrays but not scales
 * @param includeRiskBoundaries return RMP with risk boundaries included
 * @returns object filtered RMP
 */
function filterRMPByType(rmp, modelNameOrTypeCode, includeConfigureByTypeSettings = true, filterRiskScoresOnly = false, includeRiskBoundaries = true) {
  /*
  In case of configured by type RMP if we didnt clone the object it will impact
  the original object passed to this function as it's passed by reference and this function
  will always return a different result depending on the model name/type. If RMP is not
  configured by type no need to clone as it's the same object always so for better
  performance we dont need to clone the object.
   */
  let clonedRMP = rmp.configureByType ? Object.assign({}, rmp) : rmp;

  //Since this function might accept a model name or a type code, we convert it to a type code, not matter the input.
  let typeCode = modelNameOrTypeCode ? getCorrectedModelNameForRMP(CommonUtils.getTypeCodeForModelName(modelNameOrTypeCode)) : null;

  if (clonedRMP.configureByType && typeCode && exports.RISK_MODELS.find(riskModel => riskModel.model === typeCode)) {
    if (includeConfigureByTypeSettings) {
      let configureByTypeSettings = JSON.parse(clonedRMP.configureByTypeSettings);
      clonedRMP.useUncertainty = configureByTypeSettings[`use${typeCode}Uncertainty`];
      clonedRMP.useDetectability = configureByTypeSettings[`use${typeCode}Detectability`];
      clonedRMP.useCapability = configureByTypeSettings[`use${typeCode}Capability`];
      clonedRMP.keyLabel = configureByTypeSettings[`keyLabel${typeCode}`];
      clonedRMP.potentialLabel = configureByTypeSettings[`potentialLabel${typeCode}`];
    }

    for (let key of Object.keys(exports.RMP_TO_RISK_ATTRIBUTES)) {
      let riskAttribute = exports.RMP_TO_RISK_ATTRIBUTES[key];
      let rmpRiskAttribute = CommonUtils.pluralize(riskAttribute.linkedObject);
      if (filterRiskScoresOnly && !riskAttribute.isRiskScore) {
        continue;
      }
      rmpRiskAttribute = clonedRMP[rmpRiskAttribute] ? rmpRiskAttribute : `${riskAttribute.linkedObject}LinkedVersions`;
      clonedRMP[rmpRiskAttribute] = (clonedRMP[rmpRiskAttribute] || [])
        .filter(attribute => attribute.modelName === typeCode);
    }
    delete clonedRMP.boundaries;
  }
  if (clonedRMP && !clonedRMP.boundaries && includeRiskBoundaries) {
    clonedRMP.boundaries = clonedRMP ? exports.getRiskBoundaries(clonedRMP) : {};
  }
  // Links the original RMP to the filtered one, so we can always get back to the original values.
  setOriginalRMP(rmp, clonedRMP);
  clonedRMP.scoreTypeCode = typeCode;

  return clonedRMP;
}

module.exports.filterRMPByType = filterRMPByType;

/**
 * Given an RMP, this function will return back the min and max values for all risk and risk scale features of the RMP
 * @param rmp The RMP to calculate the risk boundaries
 */
module.exports.getRiskBoundaries = function(rmp) {
  let sortRisks = (a, b) => {
    return a.riskScore - b.riskScore;
  };

  let orderedImpacts = (rmp.RMPToImpacts || rmp.RMPToImpactLinkedVersions || []).sort(sortRisks);
  let orderedUncertainties = (rmp.RMPToUncertainties || rmp.RMPToUncertaintyLinkedVersions || []).sort(sortRisks);
  let orderedCapabilityRisks = (rmp.RMPToCapabilityRisks || rmp.RMPToCapabilityRiskLinkedVersions || []).sort(sortRisks);
  let orderedDetectabilityRisks = (rmp.RMPToDetectabilityRisks || rmp.RMPToDetectabilityRiskLinkedVersions || []).sort(sortRisks);
  let minCriticality = orderedImpacts.length > 0 && orderedUncertainties.length > 0
    ? orderedImpacts[0].riskScore * orderedUncertainties[0].riskScore
    : null;
  let maxCriticality = orderedImpacts.length > 0 && orderedUncertainties.length > 0
    ? orderedImpacts[orderedImpacts.length - 1].riskScore * orderedUncertainties[orderedUncertainties.length - 1].riskScore
    : null;
  let minProcessRisk = minCriticality && orderedCapabilityRisks.length > 0
    ? minCriticality * orderedCapabilityRisks[0].riskScore
    : null;
  let maxProcessRisk = maxCriticality && orderedCapabilityRisks.length > 0
    ? maxCriticality * orderedCapabilityRisks[orderedCapabilityRisks.length - 1].riskScore
    : null;
  let minRPN = minProcessRisk && orderedDetectabilityRisks.length > 0
    ? minProcessRisk * orderedDetectabilityRisks[0].riskScore
    : null;
  let maxRPN = maxProcessRisk && orderedDetectabilityRisks.length > 0
    ? maxProcessRisk * orderedDetectabilityRisks[orderedDetectabilityRisks.length - 1].riskScore
    : null;

  return {
    minImpact: orderedImpacts.length > 0 ? orderedImpacts[0].riskScore : null,
    maxImpact: orderedImpacts.length > 0 ? orderedImpacts[orderedImpacts.length - 1].riskScore : null,
    minUncertainty: orderedUncertainties.length > 0 ? orderedUncertainties[0].riskScore : null,
    maxUncertainty: orderedUncertainties.length > 0 ? orderedUncertainties[orderedUncertainties.length - 1].riskScore : null,
    minCapabilityRisk: orderedCapabilityRisks.length > 0 ? orderedCapabilityRisks[0].riskScore : null,
    maxCapabilityRisk: orderedCapabilityRisks.length > 0 ? orderedCapabilityRisks[orderedCapabilityRisks.length - 1].riskScore : null,
    minDetectabilityRisk: orderedDetectabilityRisks.length > 0 ? orderedDetectabilityRisks[0].riskScore : null,
    maxDetectabilityRisk: orderedDetectabilityRisks.length > 0 ? orderedDetectabilityRisks[orderedDetectabilityRisks.length - 1].riskScore : null,
    minCriticality: minCriticality,
    maxCriticality: maxCriticality,
    minProcessRisk: minProcessRisk,
    maxProcessRisk: maxProcessRisk,
    minRPN: minRPN,
    maxRPN: maxRPN,
    alwaysCritical: {
      impact: orderedImpacts.find(record => record.alwaysCritical),
    },
    potential: {
      uncertainty: orderedUncertainties.find(record => record.potential)
    }
  };
};

module.exports.getRMPForRiskComparisonMatrix = function(rmp, modelName, filterRiskScoresOnly = true, includeConfigureByTypeSettings = false) {
  modelName = getCorrectedModelNameForRMP(modelName);
  return rmp.configureByType ?
    this.filterRMPByType(rmp, modelName, includeConfigureByTypeSettings, filterRiskScoresOnly, false) : rmp;
};

/**
 * Given a source risk scores range and a target risk score range, this will return a mapping between the risk scores in the source
 * range and the ones in the target range. This is used when converting the risk scores of a requirement from one RMP to another.
 * So if for example in the original RMP the array of Impact risk scores is [1, 3, 5, 7, 10] and in the target RMP [1, 3, 10], this
 * will map all Impact risk scores in the source RMP to the target RMP using the formula below:
 * targetScore = newMinScore + (sourceScore - oldMinScore) * (newMaxScore - newMinScore) / (oldMaxScore - oldMinScore)
 * In the special case the source risk score has a single value, then the converted target risk score is the maximum among the target risk scores,
 * see requirements in https://cherrycircle.atlassian.net/browse/QI-2538
 * @param sourceRiskScores An array of risk scores, i.e an array of Impacts, Uncertainties, Capability Risks or Detectability risks
 * @param targetRiskScores An array of risk scores, i.e an array of Impacts, Uncertainties, Capability Risks or Detectability risks
 */
module.exports.getRMPRiskScoreConversionMap = function(sourceRiskScores, targetRiskScores) {

  let riskScoreRangeMap = {};

  sourceRiskScores = sourceRiskScores.map(x => x.riskScore).sort();
  targetRiskScores = targetRiskScores.map(x => x.riskScore).sort();

  // Find the max and min of both source and target risk scores
  let minSourceScore = Math.min(...sourceRiskScores);
  let maxSourceScore = Math.max(...sourceRiskScores);
  let minTargetScore = Math.min(...targetRiskScores);
  let maxTargetScore = Math.max(...targetRiskScores);

  if (minSourceScore > 0 && minTargetScore === 0) {
    minTargetScore = 1;
  }

  /* Iterate through the source risk scores and covert them based on the RMP conversion formula
     https://cherrycircle.atlassian.net/browse/QI-2538
   */
  for (let sourceRisk of sourceRiskScores) {
    let minDiff = Number.MAX_SAFE_INTEGER;
    let actualTargetScore;

    // When there is only 1 source risk score, then we convert this always to the max target score
    let computedTargetScore = sourceRiskScores.length > 1
      ? minTargetScore + (sourceRisk - minSourceScore) * (maxTargetScore - minTargetScore) / (maxSourceScore - minSourceScore)
      : maxTargetScore;

    /* After calculating the target risk score which can be a decimal value, we need to decide to which target score we want to round it to.
       The computed target score is rounded to the closest actual target score. The absolute distance between the computed target score and
       each actual target score is calculated and the target score with the smallest distance from the computed target score is selected.
     */
    for (let targetScore of targetRiskScores) {
      let diff = Math.abs(targetScore - computedTargetScore);
      if (minDiff >= diff) {
        minDiff = diff;
        actualTargetScore = targetScore;
      }
    }

    let sourceIsNotAssessed = exports.scoreIsNotAssessed(sourceRisk);
    let targetSupportsNotAssessed = targetRiskScores.some(scale => (isNaN(scale) && exports.scaleIsNotAssessed(scale)) || (!isNaN(scale) && exports.scoreIsNotAssessed(scale)));

    if (sourceIsNotAssessed && !targetSupportsNotAssessed) {
      actualTargetScore = maxTargetScore;
    }

    riskScoreRangeMap[sourceRisk] = actualTargetScore;
  }

  return riskScoreRangeMap;
};

/**
 * This function filters RMP risk scores conversion map by model name
 * @param conversionMap Map to be filtered
 * @param modelName Name conversion map to be filtered with
 */
module.exports.getRMPRiskScoreConversionMapByModelName = function(conversionMap, modelName) {
  if (conversionMap.configuredByType) {
    return conversionMap[getCorrectedModelNameForRMP(modelName)];
  }
  return conversionMap;
};

module.exports.getProcessCapabilityType = function(processCapabilityValue) {
  const processCapabilityTypes = Object.entries(exports.PROCESS_CAPABILITY_TYPES).map(x => x[1]).sort(CommonUtils.sortBy({name: "maxValue"}));
  return processCapabilityTypes.reduce(
    (previousItem, currentItem) => {
      if ((processCapabilityValue < currentItem.maxValue ||
          (currentItem.includingMaxValue && processCapabilityValue === currentItem.maxValue))
        && !previousItem) {
        return currentItem;
      }

      return previousItem;
    }, null,
  );
};

/**
 * Fetch the risk label for a given type.
 *
 * @param riskType The type of risk, chosen from RISK_TYPE_ENUM
 * @param rmp The RMP object from the server
 * @param {string|number} riskScore The risk value, which is a number but might be a string, such as "5"
 * @param {boolean} secureString True if the result should be secured from XSS attacks
 * @param record The record to get the risk label for in case of alwaysCritical is true
 * @param getForRiskLabel If true will get risk scale putting always critical into consideration
 * @param onlyLabel Don't return the score
 * @return {string} A string with the label, such as "5. Medium"
 * @param ignoreRiskInfo Use true when you want client side calculation of risk (in UI edit mode)
 */
module.exports.getRiskLabel = function(riskType, rmp, riskScore, record, getForRiskLabel = false, secureString = true, onlyLabel = false, ignoreRiskInfo = false) {
  let riskLabel = null;
  const hasNotAssessedScale = exports.hasNotAssessedRiskScale(riskType, rmp);
  const useRiskInfo = !ignoreRiskInfo && recordHasRiskInfo(record, riskType, rmp);
  if (useRiskInfo) {
    riskScore = record.riskInfo[riskType].value;
  }

  if (rmp && (riskScore || exports.riskValueIsNotAssessed(riskScore, riskType, rmp))) {
    if (!rmp.boundaries) {
      rmp.boundaries = exports.getRiskBoundaries(rmp);
    }

    const riskScale = exports.getRiskScale(riskType, rmp, parseFloat(riskScore), record, getForRiskLabel);

    if (!riskScale) {
      return riskScore + ". Unknown";
    }

    const riskScaleIsNotAssessed = hasNotAssessedScale && (exports.scoreIsNotAssessed(riskScale.riskScore) || exports.scaleIsNotAssessed(riskScale));
    if (riskScale.riskScore === null || riskScale.riskScore === undefined) {
      onlyLabel = true;
    }

    if (riskScale.riskScore || riskScaleIsNotAssessed) {
      riskLabel = (onlyLabel ? "" : (riskScale.riskScore + ". ")) +
        (getForRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel);
    } else {
      riskLabel = exports.getNormalizedRiskScore(riskType, rmp, parseFloat(riskScore)) + "% " +
        (getForRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel);
    }
  }

  return secureString && riskLabel ? escape(riskLabel) : (riskLabel || "Not Assessed");
};

module.exports.hasNotAssessedRiskScale = function(riskType, rmp) {
  let riskScales = exports.getRiskScales(riskType, rmp);
  if (!riskScales) {
    return false;
  }

  return riskScales.filter(scale => exports.scoreIsNotAssessed(scale.riskScore) || exports.scaleIsNotAssessed(scale)).length > 0;
};

/**
 * This function returns the normalized risk score for Criticality, ProcessRisk and RPN raw values and the raw risk score
 * for Impact, Uncertainty, Capability Risk and Detectability risk
 * @param riskType The risk type for which the normalized risk score will be returned. Can be any of RISK_TYPE_ENUM
 * @param rmp The RPM of the project
 * @param rawRiskScore The row risk score for which the normalized risk score will be returned
 */
module.exports.getNormalizedRiskScore = function(riskType, rmp, rawRiskScore) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  if (!rmp.boundaries) {
    rmp.boundaries = exports.getRiskBoundaries(rmp);
  }

  if (exports.riskValueIsNotAssessed(rawRiskScore, riskType, rmp)) {
    return exports.NOT_ASSESSED_SCORE;
  }

  let maxValue;
  if (riskType === RISK_TYPE_ENUM.CRITICALITY) {
    maxValue = rmp.boundaries.maxCriticality;
  } else if (riskType === RISK_TYPE_ENUM.PROCESS_RISK) {
    maxValue = rmp.boundaries.maxProcessRisk;
  } else if (riskType === RISK_TYPE_ENUM.RPN) {
    maxValue = rmp.boundaries.maxRPN;
  } else {
    return rawRiskScore;
  }

  if (maxValue && rawRiskScore && rawRiskScore > 0) {
    return CommonUtils.fixToMostSignificantDigit(100 * rawRiskScore / maxValue, exports.getMaxScoreLength(riskType, rmp.boundaries));
  } else {
    return null;
  }
};

module.exports.getMaxScoreLength = function(riskType, boundaries) {
  let maxScoreLength;
  let maxImpact = boundaries.maxImpact;
  let maxUncertainty = boundaries.maxUncertainty;
  let maxCapabilityRisk = boundaries.maxCapabilityRisk;
  let maxDetectabilityRisk = boundaries.maxDetectabilityRisk;
  let maxCriticalityExists = maxImpact && maxUncertainty;
  let maxProcessRiskExists = maxCriticalityExists && maxCapabilityRisk;
  let maxRPNExists = maxProcessRiskExists && maxDetectabilityRisk;

  if (riskType === RISK_TYPE_ENUM.CRITICALITY) {
    maxScoreLength = maxCriticalityExists
      ? CommonUtils.getMaxTextLengthOfNumbers(maxImpact, maxUncertainty)
      : null;
  } else if (riskType === RISK_TYPE_ENUM.PROCESS_RISK) {
    maxScoreLength = maxProcessRiskExists
      ? CommonUtils.getMaxTextLengthOfNumbers(maxImpact, maxUncertainty, maxCapabilityRisk)
      : null;
  } else if (riskType === RISK_TYPE_ENUM.RPN) {
    maxScoreLength = maxRPNExists
      ? CommonUtils.getMaxTextLengthOfNumbers(maxImpact, maxUncertainty, maxCapabilityRisk, maxDetectabilityRisk)
      : null;
  }

  return maxScoreLength;
};

/**
 * Returns the risk scales given a risk type and RMP schema
 * @param riskType The risk type as defined in RISK_TYPE_ENUM
 * @param rmp The RMP schema to get the risk scales from
 */
module.exports.getRiskScales = function(riskType, rmp) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  switch (riskType) {
    case RISK_TYPE_ENUM.IMPACT:
      return (rmp.RMPToImpacts || rmp.RMPToImpactLinkedVersions);
    case RISK_TYPE_ENUM.UNCERTAINTY:
      return (rmp.RMPToUncertainties || rmp.RMPToUncertaintyLinkedVersions);
    case RISK_TYPE_ENUM.CRITICALITY:
      return (rmp.RMPToCriticalityScales || rmp.RMPToCriticalityScaleLinkedVersions);
    case RISK_TYPE_ENUM.CAPABILITY_RISK:
      return (rmp.RMPToCapabilityRisks || rmp.RMPToCapabilityRiskLinkedVersions);
    case RISK_TYPE_ENUM.PROCESS_RISK:
      return (rmp.RMPToProcessRiskScales || rmp.RMPToProcessRiskScaleLinkedVersions);
    case RISK_TYPE_ENUM.DETECTABILITY_RISK:
      return (rmp.RMPToDetectabilityRisks || rmp.RMPToDetectabilityRiskLinkedVersions);
    case RISK_TYPE_ENUM.RPN:
      return (rmp.RMPToRPNScales || rmp.RMPToRPNScaleLinkedVersions);
  }
};

/**
 * This function returns the risk scale a given raw risk score belongs to for Criticality, Process Risk and
 * RPN raw values. For Impact, Uncertainty, Capability Risk and Detectability Risk, the respective RMP record is returned.
 * @param riskType The risk type to get the corresponding RMP risk or scale record back
 * @param rmp The RPM of the project the provided object belongs to
 * @param rawRiskScore The raw risk score based on which the risk score RMP record or risk scale scale RMP record will be calculated given the specified risk type
 * @param record The record to get the risk label for in case of alwaysCritical is true
 * @param getForRiskLabel If false risk scale returned wont put always critical into consideration
 * @param ignoreRiskInfo
 * @returns {*}
 */
module.exports.getRiskScale = function(riskType, rmp, rawRiskScore, record, getForRiskLabel = false, ignoreRiskInfo = false, riskLinks = null) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  if (!ignoreRiskInfo && recordHasRiskInfo(record, riskType, rmp)) {
    let scale = getForRiskLabel ? record.riskInfo[riskType].scaleForRiskLabel : record.riskInfo[riskType].scale;
    if (!scale) {
      scale = record.riskInfo[riskType].scale;
    }

    if (scale) {
      return scale;
    }
  }

  let riskScales = exports.getRiskScales(riskType, rmp);
  let normalizedRiskScore = exports.getNormalizedRiskScore(riskType, rmp, rawRiskScore);
  const hasNotAssessedScale = exports.hasNotAssessedRiskScale(riskType, rmp);

  let hasData = (hasNotAssessedScale && normalizedRiskScore !== null) || (!hasNotAssessedScale && normalizedRiskScore);
  if (riskType === RISK_TYPE_ENUM.CRITICALITY && record && record.obligatoryCQA) {
    hasData = true;
  }

  if (!hasData) {
    return null;
  }

  let sortedRiskScales = (riskScales && riskScales.length > 0)
    ? exports.sortRiskScales(riskScales.slice(0))
    : [];

  if (rmp.boundaries &&
    record && record.obligatoryCQA &&
    riskType === RISK_TYPE_ENUM.CRITICALITY &&
    sortedRiskScales.length > 0) {
    let sortedRecord = Object.assign({}, sortedRiskScales[0]);
    let highestRiskScale = sortedRiskScales[sortedRiskScales.length - 1];
    sortedRecord.riskLabel = highestRiskScale.riskLabel;
    sortedRecord.scoreLabel = highestRiskScale.scoreLabel;
    sortedRecord.color = highestRiskScale.color;
    return exports.cleanScale(sortedRecord);
  }

  for (let i = 0; i < sortedRiskScales.length; i++) {
    let riskScale = Object.assign({}, sortedRiskScales[i]);

    if (riskScale.riskScore !== null && riskScale.riskScore !== undefined && riskScale.riskScore === normalizedRiskScore) {
      return exports.cleanScale(riskScale);
    } else if ((i <= (hasNotAssessedScale ? 1 : 0) && Number(riskScale.from) <= normalizedRiskScore && Number(riskScale.to) >= normalizedRiskScore)
      || (i > (hasNotAssessedScale ? 1 : 0) && Number(riskScale.from) < normalizedRiskScore && Number(riskScale.to) >= normalizedRiskScore)) {

      if (record && riskType === RISK_TYPE_ENUM.CRITICALITY &&
        rmp &&
        getForRiskLabel) {

        const highestNonCriticalRiskScale = [...sortedRiskScales].reverse().find(scale => !scale.critical);
        const highestRiskScale = sortedRiskScales[sortedRiskScales.length - 1];

        const isRiskLink = (CommonUtils.isPropertyDefined(record,"parentRecordId") ||
                                    CommonUtils.isPropertyDefined(record,"effect") ||
                                    [...Object.keys(record)].filter(key => ["impact", "uncertainty"].includes(key)).length === 2) &&
                                    !CommonUtils.isPropertyDefined(record,"detailedRiskLinks") &&
                                    !CommonUtils.isPropertyDefined(record,"riskAssessmentMethod");

        const recordSupportsRiskLinks = exports.recordSupportsMultipleLinks(record);
        if (isRiskLink || !recordSupportsRiskLinks) {
          const commonCriticalityScale = new CommonCriticalityForLink(record, rmp, riskScale, highestNonCriticalRiskScale, highestRiskScale, !recordSupportsRiskLinks);
          return commonCriticalityScale.getScale();
        } else {
          const commonCriticalityScale = new CommonCriticalityForRecord(record, rmp, riskScale, highestNonCriticalRiskScale, highestRiskScale, riskLinks);
          return commonCriticalityScale.getScale();
        }
      }

      return exports.cleanScale(riskScale);
    }
  }

  return null;
};

/**
 * Remove un-needed information from risk scale, as we are reaching the session storage limit
 *
 * @param scale
 * @returns {*}
 */
module.exports.cleanScale = function(scale) {
  if (!scale) {
    return scale;
  }

  delete scale.updatedAt;
  delete scale.uuid;
  delete scale.createdAt;
  delete scale.deletedAt;
  delete scale.createdByUserId;

  return scale;
};

module.exports.sortRiskScales = function(riskScales) {
  return riskScales.sort(CommonUtils.sortBy({
    name: "from",
    primer: parseFloat,
  }, {
    name: "to",
    primer: parseFloat,
  }));
};

/**
 * This function returns a risk score for a given object based on the riskType
 * For object that support risk links, i.e IQAs, it will return back the max impact, uncertainty or criticality.
 * For objects that support risk links null will be returned if no risk link has been specified.
 * @param riskType The risk type as defined in RISK_TYPE_ENUM
 * @param rmp
 * @param object The object to return the risk score for
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 * @param isTechTransfer Is risk assessment is for tech transfer
 * @param ignoreRiskInfo Use true when you want client side calculation of risk (in UI edit mode)
 */
module.exports.getRawRiskScore = function(riskType, rmp, object, forceCalculationThroughRiskLinks, isTechTransfer, ignoreRiskInfo = false, riskLinks = null) {
  if (!ignoreRiskInfo && recordHasRiskInfo(object, riskType, rmp) && !isTechTransfer) {
    return object.riskInfo[riskType].value;
  }

  switch (riskType) {
    case RISK_TYPE_ENUM.IMPACT:
      return isTechTransfer ? CommonUtils.parseInt(object.techTransferImpact) : exports.getImpact(rmp, object, forceCalculationThroughRiskLinks);
    case RISK_TYPE_ENUM.UNCERTAINTY:
      return isTechTransfer ? CommonUtils.parseInt(object.techTransferUncertainty) : exports.getUncertainty(rmp, object, forceCalculationThroughRiskLinks);
    case RISK_TYPE_ENUM.CRITICALITY:
      return exports.getCriticality(rmp, object, forceCalculationThroughRiskLinks, isTechTransfer, riskLinks);
    case RISK_TYPE_ENUM.CAPABILITY_RISK:
      return CommonUtils.parseInt(object.capabilityRisk);
    case RISK_TYPE_ENUM.PROCESS_RISK:
      return exports.getProcessRisk(rmp, object, forceCalculationThroughRiskLinks, riskLinks);
    case RISK_TYPE_ENUM.DETECTABILITY_RISK:
      return isTechTransfer ? CommonUtils.parseInt(object.techTransferDetectability) : CommonUtils.parseInt(object.detectabilityRisk);
    case RISK_TYPE_ENUM.RPN:
      return exports.getRPN(rmp, object, forceCalculationThroughRiskLinks, riskLinks);
  }
};

/**
 * This function will return back the Impact score for a given object.
 * For objects that support risk links, i.e IQAs, it will return back the max Impact from all risk links.
 * @param rmp
 * @param object The object to return back the Impact risk score for
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 */
module.exports.getImpact = function(rmp, object, forceCalculationThroughRiskLinks) {
  if (object.obligatoryCQA) {
    return exports.getMaxImpactFromRmp(rmp);
  }

  if (object.riskLinkWinner && object.riskLinkWinner.impact) {
    return CommonUtils.parseInt(object.riskLinkWinner.impact);
  }

  if ((object.impact || exports.scoreIsNotAssessed(object.impact)) && !forceCalculationThroughRiskLinks) {
    return CommonUtils.parseInt(object.impact);
  } else {
    let riskLinks = exports.getRiskLinks(object);
    return getMaxImpact(riskLinks);
  }
};

/**
 * This function will return back the Uncertainty score for a given object.
 * For objects that support risk links, i.e IQAs, it will return back the max Uncertainty from all risk links.
 * @param object The object to return back the Uncertainty risk score for
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 */

module.exports.getUncertainty = function(rmp, object, forceCalculationThroughRiskLinks) {
  if (object.obligatoryCQA) {
    return exports.getMinUncertaintyFromRmp(rmp);
  }

  if (object.riskLinkWinner && object.riskLinkWinner.uncertainty) {
    return CommonUtils.parseInt(object.riskLinkWinner.uncertainty);
  }

  if ((object.uncertainty || exports.scoreIsNotAssessed(object.uncertainty)) && !forceCalculationThroughRiskLinks) {
    return CommonUtils.parseInt(object.uncertainty);
  } else {
    let riskLinks = exports.getRiskLinks(object);
    return getMaxUncertainty(riskLinks);
  }
};

/**
 * This will return back the max uncertainty from a collection of risk links
 * @param riskLinks The risk links array to get the max uncertainty from
 */
function getMaxUncertainty(riskLinks) {
  let maxUncertainty = null;

  for (let riskLink of riskLinks) {
    let uncertainty = exports.getUncertainty(null, riskLink);
    if (!maxUncertainty || (maxUncertainty < uncertainty)) {
      maxUncertainty = uncertainty;
    }
  }

  if (riskLinks.length > 0 && !maxUncertainty) {
    return exports.NOT_ASSESSED_SCORE;
  }

  return maxUncertainty;
}

/**
 * This will return back the max impact from a collection of risk links
 * @param riskLinks The risk links array to get the max impact from
 */
function getMaxImpact(riskLinks) {
  let maxImpact = null;

  for (let riskLink of riskLinks) {
    let impact = exports.getImpact(null, riskLink);
    if (!maxImpact || (maxImpact < impact)) {
      maxImpact = impact;
    }
  }

  if (riskLinks.length > 0 && !maxImpact) {
    return exports.NOT_ASSESSED_SCORE;
  }

  return maxImpact;
}

/**
 * Get a map of models with related models
 * @returns {{FPA: string[], FQA: string[], IQA: string[], MaterialAttribute: string[], ProcessParameter: string[], IPA: string[]}}
 */
module.exports.getRelationMap = function() {
  return {
    "MaterialAttribute": ["FQA", "IQA", "FPA", "IPA"],
    "ProcessParameter": ["FQA", "IQA", "FPA", "IPA"],
    "FQA": ["GeneralAttributeRisk"],
    "FPA": ["GeneralAttributeRisk"],
    "IQA": ["FQA", "IQA", "FPA", "IPA"],
    "IPA": ["FQA", "IQA", "FPA", "IPA"],
  };
};

/**
 * Given an object that supports risk links, this function will return back the collection of risk links.
 * @param object The object to retrieve the risk links from;
 */
module.exports.getRiskLinks = function(object) {
  const relationMap = exports.getRelationMap();

  const rules = [
    (relationKey, relatedKey) => `${relationKey}To${relatedKey}s`,
    (relationKey, relatedKey) => `${relationKey}To${relatedKey}LinkedVersions`,
  ];

  let returnValue;
  for (const relationKey of Object.keys(relationMap)) {
    const relatedEntities = relationMap[relationKey];
    for (let rule of rules) {
      if (relatedEntities.some(relatedKey => Object.keys(object).includes(rule(relationKey, relatedKey)))) {
        returnValue = relatedEntities.reduce((acc, relatedKey) => {
          return acc.concat(object[rule(relationKey, relatedKey)] || []);
        }, []);
        break;
      }
    }
  }

  if (!returnValue && object.riskLinks && object.riskLinks instanceof Array) {
    returnValue = object.riskLinks;
  } else if (!returnValue) {
    returnValue = [];
  }

  // Sort the responses so the tooltips always come out the same way (which makes the testing deterministic).
  if (returnValue && returnValue.length > 0 && returnValue[0] && returnValue[0].id) {
    returnValue = returnValue.sort((o1, o2) => o1.id < o2.id ? -1 : o1.id > o2.id ? 1 : 0);
  }

  returnValue.forEach(riskLink => {
    riskLink.source = object.modelName;
  });

  return returnValue;
};

/**
 * Calculates the criticality given an object that supports the impact and uncertainty properties
 * This should also work for objects that support risk links including the version objects of those.
 * @param rmp
 * @param object The object holding risk information to calculate criticality upon
 * @param isTechTransfer Is risk assessment is for tech transfer
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 */
module.exports.getCriticality = function(rmp, object, forceCalculationThroughRiskLinks = false, isTechTransfer = false, riskLinks = null) {
  const impactPropName = isTechTransfer ? "techTransferImpact" : "impact";
  const uncertaintyPropName = isTechTransfer ? "techTransferUncertainty" : "uncertainty";

  if (!isTechTransfer && object.obligatoryCQA) {
    return exports.getMaxCriticalityFromRmp(rmp);
  }

  if (object.riskLinkWinner && !isTechTransfer) {
    return CommonUtils.parseInt(object.riskLinkWinner[impactPropName]) * CommonUtils.parseInt(object.riskLinkWinner[uncertaintyPropName]);
  }

  if (object[impactPropName] && object[uncertaintyPropName] && !forceCalculationThroughRiskLinks) {
    return CommonUtils.parseInt(object[impactPropName]) * CommonUtils.parseInt(object[uncertaintyPropName]);
  } else if (exports.isRecordClassification(object) && !isTechTransfer) {
    return CommonUtils.parseInt(object[impactPropName]);
  }
  else {
    if (!riskLinks) {
      riskLinks = exports.getRiskLinks(object);
    }

    if ((!riskLinks || riskLinks.length === 0) &&
      !forceCalculationThroughRiskLinks &&
      (exports.scoreIsNotAssessed(object[impactPropName]) || exports.scoreIsNotAssessed(object[uncertaintyPropName]))) {
      return exports.NOT_ASSESSED_SCORE;
    }

    return exports.getMaxCriticality(riskLinks, rmp);
  }
};

/**
 * This will return back the max criticality from a collection of risk links
 * @param riskLinks The risk links array to get the max criticality from
 * @param rmp
 * @returns riskLinks or null
 */
module.exports.getMaxCriticality = function(riskLinks, rmp) {
  let maxCriticality = null;
  if (riskLinks) {
    for (let riskLink of riskLinks) {
      let criticality = exports.getCriticality(rmp, riskLink);
      if (!maxCriticality || (maxCriticality < criticality)) {
        maxCriticality = criticality;
      }
    }

    if (riskLinks.length > 0 && !maxCriticality) {
      return exports.NOT_ASSESSED_SCORE;
    }
  }

  return maxCriticality;
};

module.exports.getMaxImpactFromRmp = function(rmp) {
  if (!rmp.boundaries) {
    rmp.boundaries = exports.getRiskBoundaries(rmp);
  }

  return rmp.boundaries.maxImpact;
};

module.exports.getMinUncertaintyFromRmp = function(rmp) {
  if (!rmp.boundaries) {
    rmp.boundaries = exports.getRiskBoundaries(rmp);
  }

  return rmp.boundaries.minUncertainty;
};

module.exports.getMaxCriticalityFromRmp = function(rmp) {
  return CommonUtils.parseInt(exports.getMaxImpactFromRmp(rmp)) * CommonUtils.parseInt(exports.getMinUncertaintyFromRmp(rmp));
};

/**
 * Calculates the process risk given an object that supports the impact, uncertainty and capability risk properties
 * @param object The object holding risk information to calculate the process risk upon
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 */

module.exports.getProcessRisk = function(rmp, object, forceCalculationThroughRiskLinks, riskLinks = null) {
  let criticality = exports.getCriticality(rmp, object, forceCalculationThroughRiskLinks, false, false, riskLinks);

  if (criticality && criticality > 0) {
    return criticality * CommonUtils.parseInt(object.capabilityRisk);
  } else if (exports.scoreIsNotAssessed(criticality) || exports.scoreIsNotAssessed(object.capabilityRisk)) {
    return exports.NOT_ASSESSED_SCORE;
  } else {
    return null;
  }
};

/**
 * Calculates the RPN given an object that supports the impact, uncertainty, capability risk and detectability risk properties
 * @param object The object holding risk information to calculate the RPN upon
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 */

module.exports.getRPN = function(rmp, object, forceCalculationThroughRiskLinks, riskLinks = null) {
  let processRisk = exports.getProcessRisk(rmp, object, forceCalculationThroughRiskLinks, riskLinks);

  if (processRisk && processRisk > 0) {
    return processRisk * CommonUtils.parseInt(object.detectabilityRisk);
  } else if (exports.scoreIsNotAssessed(processRisk) || exports.scoreIsNotAssessed(object.detectabilityRisk)) {
    return exports.NOT_ASSESSED_SCORE;
  } else {
    return null;
  }
};

module.exports.parseRiskValue = function(riskValue, riskType, rmp) {
  riskValue = parseFloat(riskValue);
  const hasNotAssessedScale = exports.hasNotAssessedRiskScale(riskType, rmp);
  return hasNotAssessedScale && (isNaN(riskValue) || exports.scoreIsNotAssessed(riskValue)) ? exports.NOT_ASSESSED_SCORE : riskValue;
};

module.exports.riskValueIsNotAssessed = function(riskValue, riskType, rmp) {
  const hasNotAssessedScale = exports.hasNotAssessedRiskScale(riskType, rmp);
  return hasNotAssessedScale && exports.scoreIsNotAssessed(exports.parseRiskValue(riskValue, riskType, rmp));
};

module.exports.getDefaultRiskValue = function(riskType, rmp) {
  const hasNotAssessed = exports.hasNotAssessedRiskScale(riskType, rmp);
  const riskTypeString = riskType.toString().replaceAll(" ", "");
  if (!rmp) {
    return null;
  }

  return hasNotAssessed ? rmp.boundaries["min" + riskTypeString] : rmp.boundaries["max" + riskTypeString];
};

module.exports.riskIsDisabled = function(riskScores, name) {
  name = name.toLowerCase();
  return (name === "uncertainty" || name === "detectabilityrisk") &&
    riskScores.length === 1 && riskScores[0].riskScore === 1 && riskScores[0].scoreLabel === "Not Used";
};

module.exports.getScoreTypeDefinitions = function() {
  return [{
    name: "impact",
    table: "RMPToImpactLinkedVersions"
  }, {
    name: "uncertainty",
    table: "RMPToUncertaintyLinkedVersions"
  }, {
    name: "capabilityRisk",
    table: "RMPToCapabilityRiskLinkedVersions"
  }, {
    name: "detectabilityRisk",
    table: "RMPToDetectabilityRiskLinkedVersions"
  }];
};

module.exports.scoreIsNotAssessed = function(score) {
  if (score === undefined || score === null) {
    return false;
  }

  if (CommonUtils.isFloat(score)) {
    return score === parseFloat(exports.NOT_ASSESSED_SCORE);
  }

  return CommonUtils.parseInt(score) === exports.NOT_ASSESSED_SCORE;
};

module.exports.scaleIsNotAssessed = function(scale) {
  if (!scale) {
    return false;
  }

  return parseFloat(scale.from) === parseFloat(exports.NOT_ASSESSED_SCALE) && parseFloat(scale.to) === parseFloat(exports.NOT_ASSESSED_SCALE);
};

module.exports.getOneToManyAssociationsIdColumn = function(modelName) {
  const ONE_TO_MANY_ASSOCIATION_ID = {
    FQAToGeneralAttributeRisk: "GeneralAttributeId",
    FQAToGeneralAttributeRiskLinkedVersion: "GeneralAttributeId",

    FPAToGeneralAttributeRisk: "GeneralAttributeId",
    FPAToGeneralAttributeRiskLinkedVersion: "GeneralAttributeId",

    IQAToFQA: "FQAId",
    IQAToFQALinkedVersion: "FQAId",
    IQAToIQA: "TargetIQAId",
    IQAToIQALinkedVersion: "TargetIQAId",
    IQAToFPA: "FPAId",
    IQAToFPALinkedVersion: "FPAId",
    IQAToIPA: "IPAId",
    IQAToIPALinkedVersion: "IPAId",

    IPAToFQA: "FQAId",
    IPAToFQALinkedVersion: "FQAId",
    IPAToIQA: "IQAId",
    IPAToIQALinkedVersion: "IQAId",
    IPAToFPA: "FPAId",
    IPAToFPALinkedVersion: "FPAId",
    IPAToIPA: "TargetIPAId",
    IPAToIPALinkedVersion: "TargetIPAId",

    MaterialAttributeToFQA: "FQAId",
    MaterialAttributeToFQALinkedVersion: "FQAId",
    MaterialAttributeToIQA: "IQAId",
    MaterialAttributeToIQALinkedVersion: "IQAId",
    MaterialAttributeToFPA: "FPAId",
    MaterialAttributeToFPALinkedVersion: "FPAId",
    MaterialAttributeToIPA: "IPAId",
    MaterialAttributeToIPALinkedVersion: "IPAId",

    ProcessParameterToFPA: "FPAId",
    ProcessParameterToFPALinkedVersion: "FPAId",
    ProcessParameterToIQA: "IQAId",
    ProcessParameterToIQALinkedVersion: "IQAId",
    ProcessParameterToFQA: "FQAId",
    ProcessParameterToFQALinkedVersion: "FQAId",
    ProcessParameterToIPA: "IPAId",
    ProcessParameterToIPALinkedVersion: "IPAId",

    RMPToImpact: "RMPId",
    RMPToImpactLinkedVersion: "RMPId",
    RMPToUncertainty: "RMPId",
    RMPToUncertaintyLinkedVersion: "RMPId",
    RMPToDetectabilityRisk: "RMPId",
    RMPToDetectabilityRiskLinkedVersion: "RMPId",
    RMPToCapabilityRisk: "RMPId",
    RMPToCapabilityRiskLinkedVersion: "RMPId",
    RMPToCriticalityScale: "RMPId",
    RMPToCriticalityScaleLinkedVersion: "RMPId",
    RMPToProcessRiskScale: "RMPId",
    RMPToProcessRiskScaleLinkedVersion: "RMPId",
    RMPToRPNScale: "RMPId",
    RMPToRPNScaleLinkedVersion: "RMPId",
  };

  if (ONE_TO_MANY_ASSOCIATION_ID[modelName]) {
    return ONE_TO_MANY_ASSOCIATION_ID[modelName];
  }

  throw Error("Unknown association model: " + modelName + ".  Update meta_model.js.");
};

module.exports.attachRMPInformation = function(record, effectiveRMP) {
  if (!effectiveRMP) {
    return;
  }

  record.effectiveRMPId = effectiveRMP.RMPId;
  record.effectiveRMPVersionId = effectiveRMP.id;
};

module.exports.getRiskInfoForLink = function(riskLink, effectiveRMP) {
  const riskScore = exports.getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, riskLink);
  const normalizedValue = exports.getNormalizedRiskScore(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, riskScore);
  const scale = exports.getRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, riskScore, riskLink, false);
  // scaleForRiskLabel can be different from scale based on the alwaysCritical and other complex rules
  const scaleForRiskLabel = exports.getRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, riskScore, riskLink, true);
  const riskInfo = {};
  riskInfo[RISK_TYPE_ENUM.CRITICALITY] = {
    value: riskScore,
    normalizedValue,
    scale,
    scaleForRiskLabel,
    isCritical: scaleForRiskLabel && !!(scaleForRiskLabel.critical || scaleForRiskLabel.alwaysCritical)
  };

  const uncertainty = {};
  uncertainty.value = exports.getRawRiskScore(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP, riskLink);
  uncertainty.scale = exports.getRiskScale(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP, uncertainty.value, riskLink);
  riskInfo[RISK_TYPE_ENUM.UNCERTAINTY] = uncertainty;

  const impact = {};
  impact.value = exports.getRawRiskScore(RISK_TYPE_ENUM.IMPACT, effectiveRMP, riskLink);
  impact.scale = exports.getRiskScale(RISK_TYPE_ENUM.IMPACT, effectiveRMP, impact.value, riskLink);
  riskInfo[RISK_TYPE_ENUM.IMPACT] = impact;

  return riskInfo;
};

module.exports.getRiskInformation = function(instance, effectiveRMP, isVersion) {
  let cleanedInstance = instance;
  if (!isVersion) {
    // remove any related version information as it causes problems in getRiskLinks
    if (instance.dataValues) {
      cleanedInstance = instance.dataValues;
    }

    if (Object.keys(cleanedInstance).find(key => key.endsWith("LinkedVersions"))) {
      cleanedInstance = CommonUtils.deepClone(cleanedInstance);

      for (let propertyKey in cleanedInstance) {
        if (propertyKey.endsWith("LinkedVersions")) {
          delete cleanedInstance[propertyKey];
        }
      }
    }
  }

  const riskLinks = exports.getRiskLinks(cleanedInstance);
  const RISK_TYPE_ENUM = exports.RISK_TYPE_ENUM;

  // fill in criticality for links
  for (let riskLink of riskLinks) {
    riskLink.parentRecordName = instance.name;
    riskLink.parentRecordId = instance.id;
    if (!riskLink.riskInfo) {
      riskLink.riskInfo = exports.getRiskInfoForLink(riskLink, effectiveRMP);
    }
  }

  const forceCalculationThroughRiskLinks = exports.recordSupportsMultipleLinks(cleanedInstance);


  const criticality = {};

  // initially value is max criticality
  criticality.value = exports.getCriticality(effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, false, riskLinks);
  criticality.normalizedValue = exports.getNormalizedRiskScore(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.value);
  criticality.scale = exports.getRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.value, cleanedInstance);
  // scaleForRiskLabel can be different from scale based on the alwaysCritical and other complex rules
  criticality.scaleForRiskLabel = exports.getRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.value, cleanedInstance, true, false, riskLinks);

  // use the risk link winner for criticality value
  if (criticality.scaleForRiskLabel &&
      criticality.scaleForRiskLabel.riskLinkWinners &&
      criticality.scaleForRiskLabel.riskLinkWinners.length > 0) {
    cleanedInstance.riskLinkWinner = criticality.scaleForRiskLabel.riskLinkWinners[0];
    criticality.value = exports.getCriticality(effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, false, riskLinks);
    criticality.normalizedValue = exports.getNormalizedRiskScore(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.value);
  }

  criticality.maxValue = exports.getMaxCriticality(riskLinks, effectiveRMP);
  criticality.maxNormalizedValue = exports.getNormalizedRiskScore(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.maxValue);
  criticality.maxScale = exports.getRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.maxValue, cleanedInstance);
  criticality.maxScaleForRiskLabel = exports.getRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, criticality.maxValue, cleanedInstance, true);
  criticality.isCritical = criticality.scaleForRiskLabel && !!(criticality.scaleForRiskLabel.critical || criticality.scaleForRiskLabel.alwaysCritical);

  const impact = {};
  impact.value = exports.getRawRiskScore(RISK_TYPE_ENUM.IMPACT, effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, false, false, riskLinks);
  impact.scale = exports.getRiskScale(RISK_TYPE_ENUM.IMPACT, effectiveRMP, impact.value, cleanedInstance);

  const uncertainty = {};
  uncertainty.value = exports.getRawRiskScore(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, false, false, riskLinks);
  uncertainty.scale = exports.getRiskScale(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP, uncertainty.value, cleanedInstance);

  const capabilityRisk = {};
  capabilityRisk.value = exports.getRawRiskScore(RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, false, false, riskLinks);
  capabilityRisk.scale = exports.getRiskScale(RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP, capabilityRisk.value, instance);

  const processRisk = {};
  processRisk.value = exports.getProcessRisk(effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, riskLinks);
  processRisk.normalizedValue = exports.getNormalizedRiskScore(RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, processRisk.value);
  processRisk.scale = exports.getRiskScale(RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, processRisk.value, instance);

  const detectabilityRisk = {};
  detectabilityRisk.value = exports.getRawRiskScore(RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP, cleanedInstance, forceCalculationThroughRiskLinks, false, false, riskLinks);
  detectabilityRisk.scale = exports.getRiskScale(RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP, detectabilityRisk.value, instance);

  const RPN = {};
  RPN.value = exports.getRPN(effectiveRMP, instance, forceCalculationThroughRiskLinks, riskLinks);
  RPN.normalizedValue = exports.getNormalizedRiskScore(RISK_TYPE_ENUM.RPN, effectiveRMP, RPN.value);
  RPN.scale = exports.getRiskScale(RISK_TYPE_ENUM.RPN, effectiveRMP, RPN.value, instance);

  const result = {};
  result[RISK_TYPE_ENUM.IMPACT] = impact;
  result[RISK_TYPE_ENUM.UNCERTAINTY] = uncertainty;
  result[RISK_TYPE_ENUM.CRITICALITY] = criticality;
  result[RISK_TYPE_ENUM.CAPABILITY_RISK] = capabilityRisk;
  result[RISK_TYPE_ENUM.PROCESS_RISK] = processRisk;
  result[RISK_TYPE_ENUM.DETECTABILITY_RISK] = detectabilityRisk;
  result[RISK_TYPE_ENUM.RPN] = RPN;

  return result;
};

/***
 * CHeck if the record has risk info and the RMP is not related to Tech Transfer
 * @param record
 * @param riskType
 * @param rmp
 */
function recordHasRiskInfo(record, riskType, rmp) {
  return record && record.riskInfo && record.riskInfo[riskType] && rmp && rmp.scoreTypeCode !== "TT";
}

module.exports.getModelsWithRiskLinks = function() {
  return [...new Set(exports.RISK_MODELS.filter(riskModel => riskModel.isRiskLink)
    .map(riskModel => [riskModel.from, riskModel.to])
    .reduce((arr, curr) => {
      arr = arr.concat(curr);
      return arr;
    }, [])
  )].map(model => model === "GA" ? "GeneralAttribute" : model);
};

module.exports.modelHasRisLinks = function(modelName) {
  const modelsWithRiskLinks = exports.getModelsWithRiskLinks();
  modelName = modelName.endsWith("Version") ? modelName.replace("Version", "") : modelName;
  return modelsWithRiskLinks.includes(modelName);
};

module.exports.getDownstreamModels = function(modelName) {
  const models = [];
  _loadDownstreamModels(modelName, models);
  return models.map(modelTypeCode => CommonUtils.convertToId(CommonUtils.getModelNameForTypeCode(modelTypeCode)));
};

function _loadDownstreamModels(modelName, result) {
  modelName = CommonUtils.getTypeCodeForModelName(modelName);
  if (result.length === 0 && !exports.RISK_MODELS.find(riskModel => !riskModel.isRiskLink && CommonUtils.getTypeCodeForModelName(riskModel.model) === modelName)) {
    return result;
  }

  if (result.includes(modelName)) {
    return result;
  }

  result.push(modelName);
  result = result.concat(exports.RISK_MODELS.filter(riskModel => riskModel.isRiskLink && CommonUtils.getTypeCodeForModelName(riskModel.from) === modelName)
    .map(riskLink => CommonUtils.getTypeCodeForModelName(riskLink.to))
    .filter(modelNameInner => !result.includes(modelNameInner))).reduce((arr, curr) => {
    return _loadDownstreamModels(curr, result);
  }, []);
}

module.exports.getExtraModelsRequiredForRiskCalculations = function(modelNames) {
  return Array.from(modelNames.reduce((modelSet, modelName) => {
    const downstreamModels = exports.getDownstreamModels(modelName);
    const extraModels = downstreamModels.filter(downstreamModelName => !modelNames.includes(downstreamModelName));
    return new Set([...modelSet, ...extraModels]);
  }, new Set()));
};

module.exports.recordSupportsRiskInfo = function(record) {
  if (record.riskInfo) {
    return true;
  }

  const modelNames = this.getModelsWithRiskLinks();
  if (record.typeCode || record.modelType || record.sourceModelType) {
    const typeCode = record.typeCode || record.modelType || record.sourceModelType;
    const typeCodes = modelNames.map(modelName => CommonUtils.getTypeCodeForModelName(modelName));
    return typeCodes.includes(typeCode);
  }

  if (record.modelName) {
    const modelName = record.modelName.endsWith("Version") ? record.modelName.replace("Version", "") : record.modelName;
    return modelNames.includes(modelName);
  }

  throw new Error("Cannot detect support for risk info");
};

module.exports.isPerformanceAttribute = function(typeCode) {
  return ["IPA", "FPA"].includes(typeCode);
};

module.exports.recordSupportsMultipleLinks = function(record) {
  if (CommonUtils.isPropertyDefined(record,"detailedRiskLinks")) {
    return record.detailedRiskLinks;
  }

  if (CommonUtils.isPropertyDefined(record,"riskAssessmentMethod")) {
    return exports.isRecordRiskRanking(record);
  }

  return true;
};

/**
 * THis is used by CommonCriticalityForLink and CommonCriticalityForRecord to enable logging for criticality calculation on a specific record
 * Return -1 to disable logging
 * @returns {number}
 */
module.exports.getRecordIdForLogging = function() {
  return -1;
};

module.exports.getCriticalityReasons = function(scale) {
  let ruleInfo ="";
  if (scale.rule) {
    ruleInfo = scale.rule;
  }

  if (scale.ruleReason) {
    ruleInfo += " caused by " + scale.ruleReason;
  }

  if (scale.riskLabelBeforeOverwride) {
    ruleInfo += ". Before " + scale.rule + " rule overwrite criticality label was " + scale.riskLabelBeforeOverwride;
  }

  if (ruleInfo.trim().length === 0) {
    ruleInfo = scale.riskLabel;
  }

  return ruleInfo;
};

module.exports.getCriticalityOverrideTooltip = function(rule, riskLabel) {
  switch (rule) {
    case exports.CRITICALITY_RULES.DOWNGRADE_PREVIOUS:
      return "The linked attribute has a non-critical status. Therefore, this attribute is downgraded to the highest non-critical value in the Project’s Risk Management Plan (RMP).";
    case exports.CRITICALITY_RULES.ALWAYS_CRITICAL:
      return "Always Critical.";
    case exports.CRITICALITY_RULES.POTENTIAL:
      return `Due to the uncertainty score, the assessment for this attribute is ${riskLabel}.`;
    case exports.CRITICALITY_RULES.DOWNGRADE_KEY:
      return `The assessment is ${riskLabel}, since the impact is assessed to a performance attribute.`;
    default:
      return "";
  }
};

module.exports.getRisklabelAttributeTooltip = function() {
  return "The risk label is derived from the Project's Risk Management Plan using the resulting criticality for the record.";
};

module.exports.getRiskRankingMethodForRecord = function(record) {
  if (!record?.riskAssessmentMethod) {
    return exports.RISK_ASSESSMENT_METHOD.RISK_RANKING;
  }

  return record.riskAssessmentMethod;
};

module.exports.isRecordRiskRanking = function(record) {
  return !record.riskAssessmentMethod || record.riskAssessmentMethod === exports.RISK_ASSESSMENT_METHOD.RISK_RANKING;
};

module.exports.isRecordClassification = function(record) {
  return record.riskAssessmentMethod === exports.RISK_ASSESSMENT_METHOD.CLASSIFICATION;
};

module.exports.getRiskAssessmentMethodForModel = function(project, modelTypeCode) {
  return project && project.riskAssessmentMethod && project.riskAssessmentMethod[modelTypeCode] ||  exports.DEFAULT_RISK_ASSESSMENT_METHOD;
};

module.exports.isRiskAssessmentMethodForModelClassification = function(project, modelTypeCode) {
  return exports.getRiskAssessmentMethodForModel(project, modelTypeCode) === exports.RISK_ASSESSMENT_METHOD.CLASSIFICATION;
};

module.exports.isRiskAssessmentMethodForModelRiskRanking = function(project, modelTypeCode) {
  return exports.getRiskAssessmentMethodForModel(project, modelTypeCode) === exports.RISK_ASSESSMENT_METHOD.RISK_RANKING;
};

module.exports.isSupportingDocumentLabel = function(value) {
  return exports.RISK_SUPPORTING_DOCUMENT_LABELS.map(label => label.toLowerCase()).includes(value.toLowerCase());
};